<template>
    <section>
        <a-form class="form-container" :form="form">
            <a-row type="flex" :gutter="20">
                <a-col :span="8">
                    <a-form-item label="值班名称" >
                        <a-input placeholder="请输入" v-decorator="['dutyName',{rules:[{required:true,message:'请输入值班名称'},{max:25,message:'最大输入25个字符'}]}]" />
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="值班地点" >
                        <a-input placeholder="请输入" v-decorator="['dutyAdr',{rules:[{required:true,message:'请输入值班地点'},{max:25,message:'最大输入25个字符'}]}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            
            <a-row type="flex" :gutter="20">
                <a-col :span="8">
                    <a-form-item label="值班开始时间" >
                        <!-- <a-range-picker v-decorator="['endDate',{rules:[{required:true,message:'请选择值班时间'}]}]" /> -->
                        <a-row :gutter="12">
                            <a-col :span="12">
                                <a-date-picker :disabledDate="disabledStartDate" @change="changeStartDate" placeholder="请选择" v-decorator ="['startDate',{rules:[{required:true,message:'请选择请假开始时间'}]}]" />
                            </a-col>
                            <a-col :span="12">
                                <a-select v-model="startDateType" @change="changeDateType">
                                    <a-select-option :value="1">上午</a-select-option>
                                    <a-select-option :value="2">下午</a-select-option>
                                </a-select>
                            </a-col>
                        </a-row>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="值班结束时间" >
                        <!-- <a-range-picker v-decorator="['endDate',{rules:[{required:true,message:'请选择值班时间'}]}]" /> -->
                        <a-row :gutter="12">
                            <a-col :span="12">
                                <a-date-picker :disabledDate="disabledEndDate" @change="changeEndDate" placeholder="请选择" v-decorator ="['endDate',{rules:[{required:true,message:'请选择请假结束时间'}]}]" />
                            </a-col>
                            <a-col :span="12">
                                <a-select v-model="endDateType" @change="changeDateType">
                                    <a-select-option :value="1">上午</a-select-option>
                                    <a-select-option :value="2">下午</a-select-option>
                                </a-select>
                            </a-col>
                        </a-row>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="值班时长(天)" >
                        <a-input placeholder="0" disabled v-decorator ="['duration',{rules:[{required:true,message:'请假时间最低0.5天'}]}]"  />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="16">
                    <a-form-item label="值班说明" >
                        <a-textarea rows="4" placeholder="请输入" v-decorator="['remarks',{rules:[{required:true,message:'请输入值班说明'},{max:150,message:'最大输入150个字符'}]}]"></a-textarea>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col style="width:1080px">
                    <a-form-item :label-col="{span:2}" :wrapper-col="{span:20}" >
                        <span slot="label">值班人员</span>
                        <section class="person-list-container" v-decorator="['personSimpleInfos',{rules:[{required:true,message:'请选择值班人员'}]}]">
                            <a-button class="action-btn" @click="showSelectPerson"> <a-icon type="setting" />配置值班人员</a-button>
                            <a-row class="person-list m-t-10" v-if="personnelList.length">
                                <a-row type="flex" :gutter="10">
                                    <a-col :span="6" v-for="(item,key) in personnelList" :key="key">
                                        <a-row class="list-item">
                                            <div class="m-b-8">{{item.personnelName}}</div>
                                            <span class="desc">{{item.jobName}}</span>
                                            <a class="close" @click="deleteItem(item)"><a-icon title="删除" type="close"></a-icon></a>
                                        </a-row>
                                    </a-col>
                                </a-row>
                            </a-row>
                        </section>
                        
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row class="form-btn-group">
                <a-col :span="16">
                    <a-button @click="submit" :disabled="submitLoadding" :loading="submitLoadding">保存</a-button>
                    <a-button @click="$router.back()">取消</a-button>
                </a-col>
            </a-row>
        </a-form>


        <a-modal width="1080px" class="modal-container" v-model="visible" title="人员列表" :footer="false" >
            <selectPerson @ok="getSelectPerson" @cancel="cancelSelect" />
        </a-modal>
    </section>
</template>

<script>
import moment from 'moment'
import selectPerson from '@/components/selectPerson'
export default {
    data(){
        return{
            personnelList:[],
            visible:false,
            startDateType:1,
            endDateType:2
        }
    },
    components:{selectPerson},
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    computed:{
        did(){
            return this.$route.query.id
        },
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    mounted() {
        this.did && this.queryDutyApplyDetails()
    },
    methods:{
        disabledStartDate(startValue){
            const outDate = this.form.getFieldValue("endDate");
            if (!startValue || !outDate) return false;
            return startValue.valueOf() > outDate.valueOf();
        },
        disabledEndDate(endValue) {
            const inDate = this.form.getFieldValue("startDate");
            if (!inDate || !endValue) return false;
            return inDate.valueOf() >= endValue.valueOf();
        },
        changeStartDate(startDate){
            this.form.getFieldValue('endDate') && this.calcDuration(startDate,this.form.getFieldValue('endDate'))
        },
        changeEndDate(endDate){
            this.form.getFieldValue('startDate') && this.calcDuration(this.form.getFieldValue('startDate'),endDate)
        },
        changeDateType(){
            (this.form.getFieldValue('endDate') && this.form.getFieldValue('startDate')) && this.calcDuration(this.form.getFieldValue('startDate'),this.form.getFieldValue('endDate'))
        },
        calcDuration(startDate,endDate){
            
            let e = endDate.format('YYYY-MM-DD')
            let s = startDate.format('YYYY-MM-DD')
            let duration = moment(e).diff(s,'day')
        
            if(this.startDateType == 1){
                if(this.endDateType == 1){
                    duration += 0.5
                }else{
                    duration += 1
                }
                
            }else{
                if(e == s){
                    if(this.endDateType == 1){
                        duration = 0
                    }else{
                        duration += 0.5
                    }
                }else{
                    if(this.endDateType == 1){
                        duration += 0
                    }else{
                        duration += 0.5
                    }
                }
                
            }
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    duration:duration <= 0 ?  '' : duration
                })
            })
        },
        showSelectPerson(){
            this.visible = true
        },
        getSelectPerson(list){
            this.visible = false,
            this.personnelList = [...list]
            let personSimpleInfos = this.personnelList.map(item =>{
                return {
                    personName:item.personnelName,
                    personId:item.id
                }
            })
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    personSimpleInfos:this.personnelList.length ?  personSimpleInfos : ''
                })
            })
        },
        cancelSelect(){
            this.visible = false
            this.personnelList = []
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    personSimpleInfos: ''
                })
            })
        },
        deleteItem(list){
            this.personnelList = this.personnelList.filter(item => item.id != list.id)
            let personSimpleInfos = this.personnelList.map(item =>{
                return {
                    personName:item.personnelName,
                    personId:item.id
                }
            })
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    personSimpleInfos:this.personnelList.length ?  personSimpleInfos : ''
                })
            })
        },
        queryDutyApplyDetails(){
            this.$api.queryDutyApplyDetails(this.did).then(res =>{
                if(res.code === 200){
                    let info = (res.data && res.data.dutyApply) || {}
                    this.startDateType = info.startDateType
                    this.endDateType = info.endDateType
                    this.personnelList = (res.data && res.data.personnelList) || [] //personId、personName
                    let personSimpleInfos = this.personnelList.map(item =>{
                        return {
                            personName:item.personnelName,
                            personId:item.personnelId
                        }
                    })
                    let formData = {
                        startDate:info.startDate ? moment(info.startDate) : null,
                        endDate:info.endDate ? moment(info.endDate) : null,
                        dutyAdr:info.dutyAdr,
                        dutyName:info.dutyName,
                        remarks:info.remarks,
                        duration:info.duration,
                         personSimpleInfos:this.personnelList.length ?  personSimpleInfos : ''
                    }
                    

                    this.form.setFieldsValue(formData)
                }
            })
        },
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    formData.startDate = formData.startDate ? formData.startDate.format('YYYY-MM-DD') : null
                    formData.endDate = formData.endDate ? formData.endDate.format('YYYY-MM-DD') : null
                    formData.startDateType = this.startDateType
                    formData.endDateType = this.endDateType
                    if(this.did){
                        formData.id = this.did
                    }
                    let apiName = this.did ? 'updateDutyApply' : 'insertDutyApply'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        }
    }
}
</script>


<style lang="scss" scoped>
    .person-list-container{
        .action-btn{
            background: #3F72BA;
            border:1px solid #3F72BA;
            color:#ffffff
        }
        .person-list{
            border:1px solid rgba(76, 132, 211,0.1);
            border-radius: 4px;
            padding: 16px;
            .list-item{
                border:1px solid rgba(76, 132, 211,0.5);
                padding: 8px 10px 10px 25px;
                border-radius: 4px;
                line-height: 20px;
                color: rgba(255,255,255,0.9);
                position: relative;
                .desc{
                    color:#989898
                }
                &:hover{
                    .close{
                        display: block;
                    }
                }
                .close{
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    height: 20px;
                    width: 20px;
                    border-radius: 100%;
                    background: rgba(0,0,0,0.3);
                    font-size: 10px;
                    line-height: 20px;
                    text-align: center;
                    color: rgba(255,255,255,1);
                    border:1px solid rgba(255,255,255,0.5);
                    z-index: 99;
                    display: none;
                }
                
            }
        }
       
    }
</style>